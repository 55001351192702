import { Injectable, inject, signal } from '@angular/core';
import { Observable, finalize, tap } from 'rxjs';

import { RoomsCalendarApiService } from './rooms-calendar-api.service';
import {
  EventCalendarFiltersEnum,
  RoomsCalendarModel,
} from '../model/rooms-calendar.model';

@Injectable({
  providedIn: 'root',
})
export class RoomsCalendarService {
  readonly #roomsCalendarApiService = inject(RoomsCalendarApiService);
  readonly #isRoomsCalendarDataLoading = signal(false);
  readonly #roomsCalendarData = signal<RoomsCalendarModel[]>([]);
  #roomsCalendarDataSnapshot: RoomsCalendarModel[] = [];
  #eventStatus: EventCalendarFiltersEnum = EventCalendarFiltersEnum.ALL;

  get isRoomsCalendarDataLoading() {
    return this.#isRoomsCalendarDataLoading.asReadonly();
  }

  get roomsCalendarData() {
    return this.#roomsCalendarData.asReadonly();
  }

  set eventStatus(status: EventCalendarFiltersEnum) {
    this.#eventStatus = status;
  }

  public getRoomsEvents(
    dateFrom: string,
    dateTo: string
  ): Observable<RoomsCalendarModel[]> {
    this.#isRoomsCalendarDataLoading.set(true);
    return this.#roomsCalendarApiService.getRoomsEvents(dateFrom, dateTo).pipe(
      tap(roomsCalendarData => {
        this.#roomsCalendarDataSnapshot = roomsCalendarData;
        this.filterEvents(this.#eventStatus);
      }),
      finalize(() => this.#isRoomsCalendarDataLoading.set(false))
    );
  }

  public filterEvents(eventStatus: EventCalendarFiltersEnum) {
    if (eventStatus === EventCalendarFiltersEnum.ALL) {
      this.#roomsCalendarData.set(this.#roomsCalendarDataSnapshot);
      return;
    }
    const filteredEvents = this.#roomsCalendarDataSnapshot.map(roomEvents => ({
      ...roomEvents,
      events: roomEvents.events.filter(event => eventStatus === event.status),
    }));
    this.#roomsCalendarData.set(filteredEvents);
  }
}
