<p class="ui-heading-2-bold--s">Room management</p>

@if (isRoomListLoading()) {
  <div class="loader">
    <rds-progress-spinner></rds-progress-spinner>
  </div>
} @else {
  <bad-rooms-list-table
    [rooms]="rooms()"
    (rowClicked)="openRoomDetails($event)">
  </bad-rooms-list-table>
}
