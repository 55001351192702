@if (isLoading()) {
  <div class="loader">
    <rds-progress-spinner></rds-progress-spinner>
  </div>
}
<div class="calendar-controls">
  <rds-form-field labelPosition="none" size="m">
    <rds-select
      [value]="EventCalendarFiltersEnum.ALL"
      [formControl]="statusFilterControl">
      <rds-select-option [value]="EventCalendarFiltersEnum.ALL">
        All events
      </rds-select-option>
      <rds-select-option [value]="EventCalendarFiltersEnum.APPROVED">
        Approved
      </rds-select-option>
      <rds-select-option [value]="EventCalendarFiltersEnum.IN_PROGRESS">
        Awaiting approval
      </rds-select-option>
    </rds-select>
  </rds-form-field>
  <rds-form-field labelPosition="none" size="m">
    <input rdsInput [formControl]="dateControl" [rdsDatepicker]="picker" />
    <rds-datepicker-toggle rds-control-suffix />
    <rds-datepicker #picker></rds-datepicker>
  </rds-form-field>
  <button rds-secondary-button (click)="subWeek()">
    <rds-icon namespace="filled" icon="chevron_left"></rds-icon>
  </button>
  <button rds-secondary-button (click)="currentWeek()">Today</button>
  <button rds-secondary-button (click)="addWeek()">
    <rds-icon namespace="filled" icon="chevron_right"></rds-icon>
  </button>
</div>

<div class="calendar-container">
  <div class="header">
    <div class="calendar-cell">
      <p class="ui-label-m-bold">Meeting room</p>
    </div>
    @for (day of week(); track $index) {
      <div
        class="calendar-cell day-cell"
        [class]="
          (day | date: 'EEE, d MMM') === (today | date: 'EEE, d MMM')
            ? 'today'
            : null
        ">
        <p class="ui-label-m-bold">
          {{ day | date: 'EEE, d MMM' }}
        </p>
      </div>
    }
  </div>
  @for (room of roomsCalendarEvents(); track room.roomDexId) {
    <div class="calendar-row">
      <div class="calendar-cell room-name-cell">
        <p class="ui-heading-4-bold--s">{{ room.roomName }}</p>
      </div>
      @for (day of week(); track $index) {
        <div class="calendar-cell event-cell">
          @if (room.isMultiDayEvent) {
            @for (event of room.events; track event.bookingId) {
              @if (
                (day | date: 'MMM d') ===
                  (event.calendarDateFrom | date: 'MMM d') &&
                event.eventDurationInDays > 1
              ) {
                <ng-container
                  [ngTemplateOutlet]="eventTemplate"
                  [ngTemplateOutletContext]="{
                    room,
                    event,
                    multiDay: true,
                  }"></ng-container>
              } @else if (event.eventDurationInDays > 1) {
                <div class="event" style="visibility: hidden">spacer</div>
              }
            }
          }
          @for (event of room.events; track event.bookingId) {
            @if (
              (day | date: 'MMM d') ===
                (event.calendarDateFrom | date: 'MMM d') &&
              event.eventDurationInDays < 2
            ) {
              <ng-container
                [ngTemplateOutlet]="eventTemplate"
                [ngTemplateOutletContext]="{
                  room,
                  event,
                }"></ng-container>
            }
          }
        </div>
      }
    </div>
  }
</div>

<ng-template
  #eventTemplate
  let-event="event"
  let-room="room"
  let-multiDay="multiDay">
  <div
    (click)="openBookingDetails(event.bookingId, room.roomDexId)"
    (keydown)="openBookingDetails(event.bookingId, room.roomDexId)"
    role="button"
    tabindex="0"
    class="event"
    [class]="event.status"
    [ngStyle]="{
      width:
        'calc(' +
        event.eventDurationInDays * 100 +
        '% + ' +
        (event.eventDurationInDays === 2
          ? event.eventDurationInDays * 16 + 'px)'
          : event.eventDurationInDays * 32 - 32 + 'px)'),
    }"
    [ngClass]="multiDay && 'multi-day-event'"
    [rdsTooltip]="tooltipContent"
    rdsTooltipPosition="right-bottom"
    [rdsTooltipClass]="event.status">
    <p class="ui-label-s-bold">{{ event.eventName }}</p>
  </div>
  <ng-template #tooltipContent>
    <div [class]="event.status">
      <p class="ui-label-s-bold">
        Time: {{ event.startTime | date: 'd MMM HH:mm' }} -
        {{ event.endTime | date: 'd MMM HH:mm' }}
      </p>
      <p class="ui-label-s-bold">Name: {{ event.eventName }}</p>
      <p class="ui-label-s-bold">
        {{ event.requester.fullName }} - {{ event.numberOfPeople }} people
      </p>
      <p class="ui-label-s-bold">
        Status:
        {{ event.status === 'IN_PROGRESS' ? 'Awaiting Approval' : 'Approved' }}
      </p>
    </div>
  </ng-template>
</ng-template>
