import { DatePipe, NgClass, NgStyle, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  OnInit,
  Signal,
  WritableSignal,
  inject,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  RdsButtonModule,
  RdsDatepickerComponent,
  RdsDatepickerInputDirective,
  RdsDatepickerToggleComponent,
  RdsDialogService,
  RdsFormFieldModule,
  RdsIconComponent,
  RdsProgressSpinnerModule,
  RdsTooltipModule,
} from '@rds/angular-components';
import { eachDayOfInterval, sub } from 'date-fns';
import { add } from 'date-fns/add';
import { endOfWeek } from 'date-fns/endOfWeek';
import { format } from 'date-fns/format';
import { startOfWeek } from 'date-fns/startOfWeek';
import { distinctUntilChanged, switchMap } from 'rxjs';

import { BookingRequestDetailsComponent } from '../../../booking-request/views/booking-request-details/booking-request-details.component';
import { EventCalendarFiltersEnum } from '../../model/rooms-calendar.model';
import { RoomsCalendarService } from '../../services/rooms-calendar.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    DatePipe,
    NgClass,
    RdsButtonModule,
    RdsIconComponent,
    RdsTooltipModule,
    RdsProgressSpinnerModule,
    RdsFormFieldModule,
    ReactiveFormsModule,
    FormsModule,
    // @ts-expect-error - for some reason there is an error here, possible problem with library
    RdsDatepickerInputDirective,
    RdsDatepickerComponent,
    RdsDatepickerToggleComponent,
    RdsFormFieldModule,
    NgStyle,
    NgTemplateOutlet,
  ],
  selector: 'bad-calendar-overview',
  standalone: true,
  styleUrl: './calendar-overview.component.scss',
  templateUrl: './calendar-overview.component.html',
})
export class CalendarOverviewComponent implements OnInit {
  readonly #roomsCalendarService = inject(RoomsCalendarService);
  readonly #dialogService = inject(RdsDialogService);
  readonly #destroyRef = inject(DestroyRef);

  protected readonly roomsCalendarEvents =
    this.#roomsCalendarService.roomsCalendarData;
  protected readonly isLoading =
    this.#roomsCalendarService.isRoomsCalendarDataLoading;
  protected readonly EventCalendarFiltersEnum = EventCalendarFiltersEnum;

  protected today = new Date();
  readonly #week: WritableSignal<Date[]> = signal([]);
  #selectedDay: Date = this.today;

  protected readonly dateControl = new FormControl(this.today, {
    nonNullable: true,
  });
  protected readonly statusFilterControl =
    new FormControl<EventCalendarFiltersEnum>(EventCalendarFiltersEnum.ALL, {
      nonNullable: true,
    });

  get week(): Signal<Date[]> {
    return this.#week.asReadonly();
  }

  public ngOnInit() {
    this.#setWeek(this.today);
    this.dateControl.valueChanges
      .pipe(
        distinctUntilChanged(),
        switchMap(date => {
          this.#setWeek(date);
          this.#selectedDay = date;
          return this.#roomsCalendarService.getRoomsEvents(
            format(this.week()[0], 'yyyy-MM-dd'),
            format(this.week()[6], 'yyyy-MM-dd')
          );
        }),
        takeUntilDestroyed(this.#destroyRef)
      )
      .subscribe();
    this.dateControl.patchValue(this.#selectedDay);
    this.statusFilterControl.valueChanges
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe(status => {
        this.#roomsCalendarService.filterEvents(status);
        this.#roomsCalendarService.eventStatus = status;
      });
  }

  protected subWeek() {
    const prevWeek = sub(this.#selectedDay, { weeks: 1 });
    this.dateControl.patchValue(prevWeek);
  }

  protected addWeek() {
    const nextWeek = add(this.#selectedDay, { weeks: 1 });
    this.dateControl.patchValue(nextWeek);
  }

  protected currentWeek() {
    this.#setWeek(this.today);
    this.#selectedDay = this.today;
    this.dateControl.patchValue(this.#selectedDay);
  }

  protected openBookingDetails(bookingId: number, roomDexId: string) {
    const dialog = this.#dialogService.open(BookingRequestDetailsComponent, {
      disableClose: true,
      size: 'xl',
      maxHeight: '100%',
      data: {
        bookingId,
        roomDexId,
      },
    });

    dialog
      .afterClosed()
      .pipe(
        switchMap(() =>
          this.#roomsCalendarService.getRoomsEvents(
            format(this.week()[0], 'yyyy-MM-dd'),
            format(this.week()[6], 'yyyy-MM-dd')
          )
        ),
        takeUntilDestroyed(this.#destroyRef)
      )
      .subscribe();
  }

  #setWeek(date: Date) {
    this.#week.set(
      eachDayOfInterval({
        start: startOfWeek(date, { weekStartsOn: 1 }),
        end: endOfWeek(date, { weekStartsOn: 1 }),
      })
    );
  }
}
