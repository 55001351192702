import { Requester } from '@bookly/shared';

export interface RoomsCalendarModel {
  roomDexId: string;
  roomName: string;
  isMultiDayEvent: boolean;
  events: EventCalendarModel[];
}

export interface EventCalendarModel {
  bookingId: number;
  eventName: string;
  createTime: Date;
  startTime: Date;
  endTime: Date;
  calendarDateFrom: Date;
  status: string;
  numberOfPeople: number;
  requester: Requester;
  eventDurationInDays: number;
}

export enum EventCalendarFiltersEnum {
  ALL = 'ALL',
  APPROVED = 'APPROVED',
  IN_PROGRESS = 'IN_PROGRESS',
}
