import { AbstractControl, FormGroup, ValidationErrors } from '@angular/forms';
import { RdsFileItem } from '@rds/angular-components';

import { LayoutFormModel } from './layout-upload.component';

export function layoutUploadValidator() {
  return (
    formControl: AbstractControl<RdsFileItem[]>
  ): ValidationErrors | null => {
    const acceptedFileTypes = ['image/png', 'image/jpeg'];

    if (formControl.value.length > 0) {
      if (formControl.value.length > 1) {
        return { tooManyFiles: true };
      }

      if (formControl.value[0].file.size > 1048576) {
        return { fileSizeExceeded: true };
      }

      if (!acceptedFileTypes.includes(formControl.value[0].file.type)) {
        return { invalidFileType: true };
      }
    }

    return null;
  };
}

export function maxCapacityValidator(maxRoomCapacity: number) {
  return (formGroup: FormGroup<LayoutFormModel>): ValidationErrors | null => {
    const minCapacity = formGroup.controls.layoutMinCapacity;
    const maxCapacity = formGroup.controls.layoutMaxCapacity;

    if (maxCapacity.value < minCapacity.value) {
      maxCapacity.setErrors({ error: true });
      return { maxCapacityTooLow: true };
    } else {
      maxCapacity.setErrors(null);
    }

    if (maxCapacity.value > maxRoomCapacity) {
      maxCapacity.setErrors({ error: true });
      return { maxRoomCapacityExceeded: true };
    } else {
      maxCapacity.setErrors(null);
    }

    return null;
  };
}
