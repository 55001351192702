<table rds-table [dataSource]="dataSource">
  <ng-container rdsColumnDef="default">
    <th rds-header-cell *rdsHeaderCellDef>Default</th>
    <td rds-cell *rdsCellDef="let element">
      <rds-radio-button
        (buttonChanged)="selectLayout(element.layoutDexUUID)"
        [value]="element.layoutDexUUID"
        [checked]="defaultLayout === element.layoutDexUUID"></rds-radio-button>
    </td>
  </ng-container>

  <ng-container rdsColumnDef="imageURL">
    <th rds-header-cell *rdsHeaderCellDef>Layout</th>
    <td rds-cell *rdsCellDef="let element">
      <div class="layout-image">
        <img [ngSrc]="element.imageURL" fill alt="layout image" />
      </div>
    </td>
  </ng-container>

  <ng-container rdsColumnDef="layoutName">
    <th rds-header-cell *rdsHeaderCellDef>Description</th>
    <td rds-cell *rdsCellDef="let element">{{ element.layoutName }}</td>
  </ng-container>

  <ng-container rdsColumnDef="capacity">
    <th rds-header-cell *rdsHeaderCellDef>Capacity</th>
    <td rds-cell *rdsCellDef="let element">
      {{ element.capacity.min }}-{{ element.capacity.max }}
    </td>
  </ng-container>

  <ng-container rdsColumnDef="setupTime">
    <th rds-header-cell *rdsHeaderCellDef>Setup Time</th>
    <td rds-cell *rdsCellDef="let element">
      <p>{{ element.setupTime.before }} mins. before</p>
      <p>{{ element.setupTime.after }} mins. after</p>
    </td>
  </ng-container>

  <ng-container rdsColumnDef="actions">
    <th rds-header-cell *rdsHeaderCellDef></th>
    <td rds-cell *rdsCellDef="let element">
      <button
        rds-icon-button
        size="m"
        (click)="deleteLayout(element.layoutDexUUID)"
        [disabled]="element.layoutDexUUID === defaultLayout">
        <rds-icon namespace="outlined" icon="delete"></rds-icon>
      </button>
    </td>
  </ng-container>

  <tr rds-header-row *rdsHeaderRowDef="displayedColumns"></tr>
  <tr rds-row *rdsRowDef="let row; columns: displayedColumns"></tr>
</table>
