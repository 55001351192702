import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  RdsBadgeModule,
  RdsButtonModule,
  RdsIconsModule,
  RdsSortDirective,
  RdsTableDataSource,
  RdsTableModule,
} from '@rds/angular-components';

import { RoomBookingModel } from '@bookly/shared';

@Component({
  selector: 'bad-room-bookings-table',
  templateUrl: './room-bookings-table.component.html',
  styleUrls: ['./room-bookings-table.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [RdsBadgeModule, RdsButtonModule, RdsIconsModule, RdsTableModule],
})
export class RoomBookingsTableComponent implements AfterViewInit {
  @Input() public set roomBooking(value: RoomBookingModel[] | null) {
    if (value) {
      this.dataSource.data = value;
    }
  }
  @Output() public rowClicked = new EventEmitter<RoomBookingModel>();

  protected readonly dataSource = new RdsTableDataSource<RoomBookingModel>(
    []
  ).init();

  protected readonly displayedColumns: (
    | keyof RoomBookingModel
    | 'actions'
    | 'multiselect'
  )[] = [
    'id',
    'roomDescription',
    'status',
    'startTime',
    'requester',
    'actions',
  ];
  @ViewChild(RdsSortDirective) sort: RdsSortDirective | null = null;

  public ngAfterViewInit(): void {
    this.dataSource.sortingDataAccessor = this.sortingDataAccessor;
    this.dataSource.sort = this.sort;
  }

  protected clickRowHandler(rowData: RoomBookingModel) {
    this.rowClicked.emit(rowData);
  }

  private sortingDataAccessor(
    data: RoomBookingModel,
    sortHeaderId: string
  ): string | number {
    switch (sortHeaderId) {
      case 'startTime':
        return new Date(data.startDate).setHours(
          Number(data.startTime.split(':')[0]),
          Number(data.startTime.split(':')[1])
        );
      case 'requester':
        return data.requester.fullName;
      case 'status':
        return data.status.text;
      default:
        return data[sortHeaderId as keyof RoomBookingModel] as string;
    }
  }
}
