import { differenceInCalendarDays, isAfter } from 'date-fns';
import { isBefore } from 'date-fns/isBefore';

import { RoomsCalendarApiModel } from '../model/rooms-calendar-api.model';
import { RoomsCalendarModel } from '../model/rooms-calendar.model';

export function roomsCalendarConverter(
  roomsCalendarApiResponse: RoomsCalendarApiModel[],
  dateFrom: string,
  dateTo: string
): RoomsCalendarModel[] {
  return roomsCalendarApiResponse.map(roomEvent => ({
    roomDexId: roomEvent.roomDexId,
    roomName: roomEvent.roomName,
    isMultiDayEvent: roomEvent.events.some(
      event => differenceInCalendarDays(event.endTime, event.startTime) + 1 > 1
    ),
    events: roomEvent.events.map(event => {
      const calendarDateFrom = isBefore(event.startTime, dateFrom)
        ? dateFrom
        : event.startTime;
      const calendarDateTo = isAfter(event.endTime, dateTo)
        ? dateTo
        : event.endTime;
      return {
        ...event,
        startTime: new Date(event.startTime),
        endTime: new Date(event.endTime),
        createTime: new Date(event.createTime),
        calendarDateFrom: new Date(calendarDateFrom),
        eventDurationInDays:
          differenceInCalendarDays(calendarDateTo, calendarDateFrom) + 1,
      };
    }),
  }));
}
