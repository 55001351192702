<table rds-table [dataSource]="dataSource" rdsSort>
  <ng-container rdsColumnDef="name">
    <th rds-header-cell *rdsHeaderCellDef rds-sort-header>Room name</th>
    <td rds-cell *rdsCellDef="let element">
      {{ element.name }}
    </td>
  </ng-container>

  <ng-container rdsColumnDef="image">
    <th rds-header-cell *rdsHeaderCellDef></th>
    <td rds-cell *rdsCellDef="let element">
      <div class="image">
        @if (element.photo?.url) {
          <img [ngSrc]="element.photo.url" alt="room image" fill />
        }
      </div>
    </td>
  </ng-container>

  <ng-container rdsColumnDef="description">
    <th rds-header-cell *rdsHeaderCellDef rds-sort-header>Description</th>
    <td rds-cell *rdsCellDef="let element">
      {{ element.description }}
    </td>
  </ng-container>

  <ng-container rdsColumnDef="type">
    <th rds-header-cell *rdsHeaderCellDef rds-sort-header>Room Type</th>
    <td rds-cell *rdsCellDef="let element">
      {{ element.type }}
    </td>
  </ng-container>

  <ng-container rdsColumnDef="capacity">
    <th rds-header-cell *rdsHeaderCellDef rds-sort-header>Capacity</th>
    <td rds-cell *rdsCellDef="let element">
      {{ element.capacity }}
    </td>
  </ng-container>

  <ng-container rdsColumnDef="actions">
    <th rds-header-cell *rdsHeaderCellDef rds-sort-header></th>
    <td rds-cell *rdsCellDef="let element">
      <button rds-icon-button size="m">
        <rds-icon namespace="filled" icon="more_vertical"></rds-icon>
      </button>
    </td>
  </ng-container>

  <tr rds-header-row *rdsHeaderRowDef="displayedColumns"></tr>
  <tr
    rds-row
    *rdsRowDef="let row; columns: displayedColumns"
    (click)="clickRowHandler(row)"></tr>
</table>
