import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  OnInit,
  inject,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  RDS_DIALOG_DATA,
  RdsButtonModule,
  RdsDialogModule,
  RdsDialogRef,
  RdsDividerModule,
  RdsFileItem,
  RdsFileUploader,
  RdsFormFieldModule,
  RdsProgressSpinnerModule,
  RdsUploadModule,
} from '@rds/angular-components';
import { catchError, filter, finalize, map, of, switchMap } from 'rxjs';

import { RoomsModel } from '@bookly/shared';

import {
  layoutUploadValidator,
  maxCapacityValidator,
} from './layout-upload.validator';
import { RoomsService } from '../../services/rooms.service';

export interface LayoutFormModel {
  layoutName: FormControl<string>;
  layoutFile: FormControl<RdsFileItem[]>;
  layoutMinCapacity: FormControl<number>;
  layoutMaxCapacity: FormControl<number>;
  layoutSetupTimeBefore: FormControl<number>;
  layoutSetupTimeAfter: FormControl<number>;
}

@Component({
  selector: 'bad-layout-upload',
  standalone: true,
  imports: [
    RdsDialogModule,
    RdsButtonModule,
    RdsDividerModule,
    RdsUploadModule,
    ReactiveFormsModule,
    RdsFormFieldModule,
    RdsProgressSpinnerModule,
  ],
  templateUrl: './layout-upload.component.html',
  styleUrl: './layout-upload.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutUploadComponent implements OnInit {
  readonly #roomsService = inject(RoomsService);
  readonly #fb = inject(FormBuilder);
  readonly #rdsDialogRef =
    inject<RdsDialogRef<LayoutUploadComponent>>(RdsDialogRef);
  readonly #destroyRef = inject(DestroyRef);
  #createdLayoutId: string | null = null;
  readonly #selectedImage = signal<string | null>(null);
  readonly #isLayoutUploading = signal(false);

  protected readonly roomDetails = inject(RDS_DIALOG_DATA) as RoomsModel;

  get isLayoutUploading() {
    return this.#isLayoutUploading.asReadonly();
  }

  get selectedImage() {
    return this.#selectedImage.asReadonly();
  }

  protected uploader = new RdsFileUploader({
    url: '',
    method: 'PUT',
    accept: ['image/jpeg', 'image/png'],
    maxFileSize: 1048576,
  });

  readonly layoutForm: FormGroup<LayoutFormModel> = this.#fb.group(
    {
      layoutName: this.#fb.nonNullable.control<string>('', [
        Validators.required,
      ]),
      layoutFile: this.#fb.nonNullable.control<RdsFileItem[]>(
        [],
        [Validators.required, layoutUploadValidator()]
      ),
      layoutMinCapacity: this.#fb.nonNullable.control(10, [
        Validators.required,
        Validators.min(1),
      ]),
      layoutMaxCapacity: this.#fb.nonNullable.control(
        this.roomDetails.capacity,
        [Validators.required]
      ),
      layoutSetupTimeBefore: this.#fb.nonNullable.control(15, [
        Validators.required,
        Validators.min(0),
      ]),
      layoutSetupTimeAfter: this.#fb.nonNullable.control(15, [
        Validators.required,
        Validators.min(0),
      ]),
    },
    {
      validators: maxCapacityValidator(
        this.roomDetails.capacity
      ) as ValidatorFn,
    }
  );

  public ngOnInit() {
    this.uploader.queue$
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe(rdsFile => {
        const fileUploadButton: HTMLButtonElement | null =
          document.querySelector('button[rds-upload-drop-zone]');
        if (rdsFile.length >= 1 && fileUploadButton) {
          fileUploadButton.style.display = 'none';

          this.layoutForm.controls.layoutFile.updateValueAndValidity();
          if (this.layoutForm.controls.layoutFile.valid) {
            const fileReader = new FileReader();

            fileReader.readAsDataURL(rdsFile[0].file);

            fileReader.onload = event => {
              const image = event.target?.result as string;
              this.#selectedImage.set(image);
            };
          }
        }
        if (
          rdsFile.length === 0 &&
          fileUploadButton?.style.display === 'none'
        ) {
          this.#selectedImage.set(null);
          fileUploadButton.style.display = '';
        }
      });
  }

  protected upload() {
    this.#isLayoutUploading.set(true);
    const request = {
      roomDexId: this.roomDetails.roomDexId,
      layoutName: this.layoutForm.controls.layoutName.value,
      fileName: this.layoutForm.controls.layoutFile.value[0].file.name,
      capacity: {
        min: this.layoutForm.controls.layoutMinCapacity.value,
        max: this.layoutForm.controls.layoutMaxCapacity.value,
      },
      setupTime: {
        before: this.layoutForm.controls.layoutSetupTimeBefore.value,
        after: this.layoutForm.controls.layoutSetupTimeAfter.value,
      },
    };

    this.#roomsService
      .uploadLayout(request)
      .pipe(
        switchMap(resp => {
          this.#createdLayoutId = resp.roomLayoutDTO.layoutDexUUID;
          return this.#roomsService.uploadLayoutFile(
            resp.presignedUrl,
            this.layoutForm.controls.layoutFile.value[0].file
          );
        }),
        map(() => true),
        catchError(err => {
          this.uploader.uploadAll();
          if (this.#createdLayoutId) {
            return this.#roomsService.deleteLayout(this.#createdLayoutId);
          }

          return of(err);
        }),
        filter(Boolean),
        takeUntilDestroyed(this.#destroyRef),
        finalize(() => this.#isLayoutUploading.set(false))
      )
      .subscribe(resp => {
        this.#rdsDialogRef.close(resp);
      });
  }
}
