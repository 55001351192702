import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, map } from 'rxjs';

import { environment } from '@bookly/shared';

import { roomsCalendarConverter } from './rooms-calendar.converter';
import { RoomsCalendarApiModel } from '../model/rooms-calendar-api.model';
import { RoomsCalendarModel } from '../model/rooms-calendar.model';

@Injectable({
  providedIn: 'root',
})
export class RoomsCalendarApiService {
  readonly #http = inject(HttpClient);
  readonly #roomsCalendarUrl = environment.admin.roomsCalendarApi;

  public getRoomsEvents(
    dateFrom: string,
    dateTo: string
  ): Observable<RoomsCalendarModel[]> {
    return this.#http
      .get<RoomsCalendarApiModel[]>(this.#roomsCalendarUrl, {
        params: { from: dateFrom, to: dateTo },
      })
      .pipe(
        map(roomsCalendarEvents =>
          roomsCalendarConverter(roomsCalendarEvents, dateFrom, dateTo)
        )
      );
  }
}
