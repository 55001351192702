<h2 rds-dialog-header style="width: 100%">Upload layout</h2>
<rds-divider></rds-divider>
<div rds-dialog-content [formGroup]="layoutForm">
  <div class="upload">
    @if (selectedImage(); as selectedImage) {
      <img [src]="selectedImage" alt="selected image" />
    }

    <rds-upload formControlName="layoutFile" hasDropZone [uploader]="uploader">
      @if (layoutForm.controls.layoutFile.errors?.['fileSizeExceeded']) {
        <rds-upload-error>
          File size exceeded. Max file size is 1mb
        </rds-upload-error>
      }
      @if (layoutForm.controls.layoutFile.errors?.['invalidFileType']) {
        <rds-upload-error>
          Invalid file type. Accepted file types png and jpeg
        </rds-upload-error>
      }
      @if (layoutForm.controls.layoutFile.errors?.['tooManyFiles']) {
        <rds-upload-error> Only one file is allowed </rds-upload-error>
      }
    </rds-upload>
  </div>

  <rds-form-field labelPosition="top">
    <rds-control-label>Description of the layout</rds-control-label>
    <input formControlName="layoutName" rdsInput />
  </rds-form-field>

  <div class="capacity-wrapper">
    <p class="ui-label-m-bold">Capacity of people<span>*</span></p>
    <div class="capacity-form-controls">
      <rds-form-field labelPosition="top">
        <input
          type="number"
          formControlName="layoutMinCapacity"
          min="1"
          rdsInput />
      </rds-form-field>
      <p>to</p>
      <rds-form-field labelPosition="top">
        <input
          type="number"
          formControlName="layoutMaxCapacity"
          min="1"
          [max]="roomDetails.capacity"
          rdsInput />
      </rds-form-field>
    </div>
    @if (layoutForm.errors?.['maxCapacityTooLow']) {
      <rds-control-error>
        Max capacity have to be equal or greater min capacity
      </rds-control-error>
    }
    @if (layoutForm.errors?.['maxRoomCapacityExceeded']) {
      <rds-control-error>
        Max capacity cannot be greater than room max capacity ({{
          this.roomDetails.capacity
        }})
      </rds-control-error>
    }
  </div>
  <div class="setup-time-wrapper">
    <div>
      <rds-form-field labelPosition="top">
        <rds-control-label>Setup time before</rds-control-label>
        <input
          type="number"
          formControlName="layoutSetupTimeBefore"
          min="0"
          rdsInput />
      </rds-form-field>
    </div>
    <p>mins.</p>
    <div>
      <rds-form-field labelPosition="top">
        <rds-control-label>Setup time after</rds-control-label>
        <input
          type="number"
          formControlName="layoutSetupTimeAfter"
          min="0"
          rdsInput />
      </rds-form-field>
    </div>
    <p>mins.</p>
  </div>
</div>
<div rds-dialog-actions-container>
  <button rds-secondary-button [rds-dialog-close]="false" size="m">
    Cancel
  </button>
  <button
    rds-primary-button
    size="m"
    (click)="upload()"
    [disabled]="layoutForm.invalid">
    Submit
  </button>
</div>
@if (isLayoutUploading()) {
  <div class="loader">
    <rds-progress-spinner></rds-progress-spinner>
  </div>
}
